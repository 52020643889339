export enum ContainerTypes {
    FORM = "FORM",
    TABS = "TABS",
    TAB_ITEM = "TAB_ITEM",
    CARD = "CARD",
    COLLAPSIBLE = "COLLAPSIBLE",
    EMPTY_BLOCK = "EMPTY_BLOCK",
}

export enum Width {
    FULL = "FULL",
    HALF = "HALF",
    QUARTER = "QUARTER",
}

export enum IconPosition {
    LEFT = "LEFT",
    RIGHT = "RIGHT",
}

export enum FieldTypes {
    SINGLELINE = "SINGLELINE",
    MULTILINE = "MULTILINE",
    DROPDOWN = "DROPDOWN",
    DROPDOWNREF = "DROPDOWNREF",
    RANGE = "RANGE",
    DROPDOWN_TEMPLATE = "DROPDOWN_TEMPLATE",
    CHECKBOX = "CHECKBOX",
    MULTICHECKBOX = "MULTICHECKBOX",
    MULTICHECKBOXREF = "MULTICHECKBOXREF",
    DATE = "DATE",
    TIME = "TIME",
    DATETIME = "DATETIME",
    DATERANGE = "DATERANGE",
    RADIO = "RADIO",
    RADIO_TEMPLATE = "RADIO_TEMPLATE",
    RADIOREF = "RADIOREF",
    NUMERICS = "NUMERICS",
    NUMBERRANGE = "NUMBERRANGE",
    FILEUPLOAD = "FILEUPLOAD",
    HIDDEN = "HIDDEN",
    IMAGE = "IMAGE",
    PASSWORD = "PASSWORD",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    COLORPICKER = "COLORPICKER",
    RICHTEXT = "RICHTEXT",
    SPACER = "SPACER",
    SIMPLE_HORIZONTAL_LIST = "SIMPLE_HORIZONTAL_LIST",
    SIMPLE_VERTICAL_LIST = "SIMPLE_VERTICAL_LIST",
    LABEL = "LABEL",
    STATIC_FIELDS_BUILDER = "STATIC_FIELDS_BUILDER",
    LINK_DIALOG = "LINK_DIALOG",
    CHILD_FORM_LIST = "CHILD_FORM_LIST",
    TABLE = "TABLE",
    TABLEMANAGER = "TABLEMANAGER",
    TABLE_DIALOG = "TABLE_DIALOG",
    DIVIDERPANEL = "DIVIDERPANEL",
    AUTOCOMPLETE = "AUTOCOMPLETE",
    AUTOCOMPLETEMULTIPLE = "AUTOCOMPLETEMULTIPLE",
    LISTVIEW = "LISTVIEW",
    MULTISELECTDROPDOWNREF = "MULTISELECTDROPDOWNREF",
    MULTISELECTDROPDOWN = "MULTISELECTDROPDOWN",
    REPORTCHECKBOXREF = "REPORTCHECKBOXREF"
}

export enum ActionSource {
    LINK = "LINK",
    INPUT_CONTROL = "INPUT_CONTROL",
    SIMPLE_BUTTON = "SIMPLE_BUTTON",
    ICON = "ICON",
    SAVE_BUTTON = "SAVE_BUTTON",
    CLEAR_BUTTON = "CLEAR_BUTTON",
    CANCEL_BUTTON = "CANCEL_BUTTON",
}

export enum EventTypes {
    BLUR = "BLUR",
    CHANGE = "CHANGE",
    CHANGED = "CHANGED",
    OPTION_SELECTED = "OPTION_SELECTED",
    CLICK = "CLICK",
    DBL_CLICK = "DBL_CLICK",
    ERROR = "ERROR",
    FOCUS = "FOCUS",
    KEYDOWN = "KEYDOWN",
    KEYPRESS = "KEYPRESS",
    KEYUP = "KEYUP",
    LOAD = "LOAD",
    MOUSEDOWN = "MOUSEDOWN",
    MOUSEUP = "MOUSEUP",
    MOUSEOUT = "MOUSEOUT",
    MOUSEMOVE = "MOUSEMOVE",
    MOUSEOVER = "MOUSEOVER",
    PROGRESS = "PROGRESS",
    READYSTAE = "READYSTATE",
    RESIZE = "RESIZE",
    RESET = "RESET",
    SCROLL = "SCROLL",
    SELECT = "SELECT",
    SUBMIT = "SUBMIT",
}

export enum ButtonTextTypes {
    SAVE = "SAVE",
    SUBMIT = "SUBMIT",
    CANCEL = "CANCEL",
    CLOSE = "CLOSE",
    RUNREPORT = "RUN REPORT"
}


export enum DynamicValueOptionsType {
    CURRENT_NUMBER = "CURRENT_NUMBER"
}

export enum ComputedOptionsType {
    DAY_OF_WEEK = "DAY_OF_WEEK"
}

export enum ComparisionTypes {
    NOT_EQUAL = "NOT_EQUAL",
    EQUAL = "EQUAL",
    NOT = "NOT",
    LESS_THAN = "LESS_THAN",
    LESS_THAN_EQUAL = "LESS_THAN_EQUAL",
    GREATER_THAN = "GREATER_THAN",
    GREATER_THAN_EQUAL = "GREATER_THAN_EQUAL"
}


export enum ArithmeticTypes {
    INITIAL = "INITIAL",
    ADDITION = "ADDITION",
    SUBTRACTION = "SUBTRACTION",
    MULTIPLICATION = "MULTIPLICATION",
    DIVISION = "DIVISION",
}

export enum ConditionTypes {
    IF = "IF",
    ELSE = "ELSE"
}

export enum ExecuteMethods {
    SET_VARIABLE = "SET_VARIABLE",
    SET_VARIABLE_FROM_EXECUTIONS = "SET_VARIABLE_FROM_EXECUTIONS",
    GET_VALUE = "GET_VALUE",
    CONDITION = "CONDITION",
    PUSH = "PUSH",
    FIND = "FIND",
    SORT = "SORT",
    FILTER = "FILTER",
    API_EXECUTIONS = "API_EXECUTIONS",
    FOR_EACH = "FOR_EACH",
    CONSOLE_LOG = "CONSOLE_LOG",
    OBJECT_CREATION = "OBJECT_CREATION",
    ARITHMETIC = "ARITHMETIC",
    CALL_FUNCTION = "CALL_FUNCTION",
    EXECUTIONS = "EXECUTIONS",
    CALL_EXECUTIONS = "CALL_EXECUTIONS",
    SET_FUNCTION = "SET_FUNCTION",
    JSON_PARSE = "JSON_PARSE",
    SPREAD_OPERATOR = "SPREAD_OPERATOR"
}

export enum ValueTypes {
    FIELD = "FIELD",
    STATIC = "STATIC",
    EXECUTIONS = "EXECUTIONS"
}

export enum ValueSource {
    COMPONENT = "COMPONENT",
    HANDLER = "HANDLER",
    LOCAL_VARIABLE = "LOCAL_VARIABLE",
    BUILT_IN = "BUILT_IN"
}

export enum BuiltInValues {
    WINDOW = "WINDOW"
}

export enum ActionTypes {
    MULTIBUTTONS = "MULTIBUTTONS"
}

export enum TemplateDataAuxiliaryName {
    ALLEGATION,
    NUMBER
}

export enum ComparisonSubTypes {
    INITIAL = "INITIAL",
    OR = "OR",
    AND = "AND"
}