import { withFormWrapper } from "@/handlers/form-builder/FormWrapper";
import {
  App,
  defineAsyncComponent
} from "vue";

const install = (app: App): void => {
  app.component("c-button", defineAsyncComponent(() => import("./common/ButtonComponent.vue")));
  app.component("c-card", defineAsyncComponent(() => import("./common/CardComponent.vue")));
  app.component("c-collapsible", defineAsyncComponent(() => import("./common/CollapsibleComponent.vue")));
  app.component("c-date", defineAsyncComponent(() => import("./common/DateComponent.vue")));
  app.component("c-multiDate", defineAsyncComponent(() => import("./common/MultiDateComponent.vue")));
  app.component("c-dropdown", defineAsyncComponent(() => import("./common/DropDownComponent.vue")));
  app.component("c-table", defineAsyncComponent(() => import("./common/Table/TableComponent.vue")));
  app.component("c-upload", defineAsyncComponent(() => import("./common/FileUploadComponent.vue")));
  app.component("c-dialog", defineAsyncComponent(() => import("./common/DialogComponent.vue")));
  app.component("c-confirmDialog", defineAsyncComponent(() => import("./common/ConfirmDialogComponent.vue")));
  app.component("c-form", withFormWrapper(defineAsyncComponent(() => import("./form-builder/FormBuilder.vue"))));
  app.component("c-formactions", defineAsyncComponent(() => import("./form-builder/FormActionsBuilder.vue")));
  app.component("c-formcontainers", defineAsyncComponent(() => import("./form-builder/FormContainersBuilder.vue")));
  app.component("c-formfields", defineAsyncComponent(() => import("./form-builder/FormFieldsBuilder.vue")));

  app.component("c-textbox", defineAsyncComponent(() => import("./common/TextBoxComponent.vue")));
  app.component("c-password", defineAsyncComponent(() => import("./common/PasswordBoxComponent.vue")))
  app.component("c-textarea", defineAsyncComponent(() => import("./common/TextAreaComponent.vue")));
  app.component("c-radio", defineAsyncComponent(() => import("./common/RadioButtonComponent.vue")));
  app.component("c-autocomplete", defineAsyncComponent(() => import("./common/AutoCompleteComponent.vue")));
  app.component("c-autocompleteMultiple", defineAsyncComponent(() => import("./common/AutoCompleteMultiComponent.vue")));
  app.component("c-inputnumber", defineAsyncComponent(() => import("./common/InputNumberComponent.vue")));
  app.component("c-richtextbox", defineAsyncComponent(() => import("./common/RichTextBoxComponent.vue")));

  app.component("c-skeleton", defineAsyncComponent(() => import("./common/PageLoader/GridLoaderComponent.vue")));
  app.component("c-pageblocker", defineAsyncComponent(() => import("./common/PageLoader/PageBlockComponent.vue")));
  app.component("c-spinner", defineAsyncComponent(() => import("./common/PageLoader/SpinnerComponent.vue")));
  app.component("c-validation-listener", defineAsyncComponent(() => import("./validation/ValidationListener.vue")));

  app.component("c-scrolltop", defineAsyncComponent(() => import("./common/ScrollTopComponent.vue")));
  app.component("c-querybuilder", defineAsyncComponent(() => import("./common/QueryBuilderComponent.vue")))
  app.component("c-runquery", defineAsyncComponent(() => import("./common/RunQueryComponent.vue")));
  app.component("c-overlaypanel", defineAsyncComponent(() => import("./common/OverlayPanelComponent.vue")));
  app.component("c-badge", defineAsyncComponent(() => import("./common/BadgeComponent.vue")));
  app.component("c-fragment", defineAsyncComponent(() => import("./common/FragmentComponent.vue")));
  app.component("c-multi-checkbox", defineAsyncComponent(() => import("./common/MultiCheckboxComponent.vue")));
  app.component("c-checkbox", defineAsyncComponent(() => import("./common/CheckboxComponent.vue")));
  app.component("c-multiselect", defineAsyncComponent(() => import("./common/MultiSelectComponent.vue")))
}

export default { install };

export const FormBuilder = withFormWrapper(
  defineAsyncComponent(
    () => import("@/components/form-builder/FormBuilder.vue")
  )
)
