import {
    DynamicApi,
    execution
} from "@/models/FormBuilderModel";
import { Options } from "vue-class-component";
import { Alert } from "@/models/Alert";
import { Container } from "inversify";
import { IProfileService } from "@/services/Profile/IProfileService";
import { IoCTypes } from "@/IoC/IoCTypes";
import { bindProfileService } from "@/IoC/ProfileIoC";
import { ProfileResponse } from "@/models/Profile";
import { inject } from "vue";
import BaseHandler from "../BaseHandler";
import { bindFormExecutionService } from "@/IoC/FormBuilderIoC";
import { IFormExecutionService } from "@/services/form-builder/form-execution/IFormExecutionService";
import { CustomFunction } from "./custom-function/CustomFunction";
import { ILookupService } from "@/services/lookup/ILookpupService";
import {
    ageCalculation,
    flatteningJson,
    Helpers,
    calculateYearDifference,
    getMonthDifference,
    isOnlySpaces,
    addingDays
} from "@/lib/Util";
import { bindFacilityListService } from "@/IoC/FacilityIoC";
import { IFacilityListService } from "@/services/facility/facility-list/IFacilityListService";
import { ICommonStoreService } from "@/services/common/ICommonStoreService";
import { bindCommonStoreService } from "@/IoC/CommonIoC";

type TProvide = {
    alert: Alert;
    stateConnectors: any;
    executeFunction: (executions: execution[], currentObject?: any) => any;
    attachmentData: any;
    profile: () => ProfileResponse,
    clearAttachments: (res: any) => void
}

@Options({
    template: `<slot></slot>`,
    provide(): TProvide {
        return {
            alert: this.alert,
            stateConnectors: this.stateConnectors,
            executeFunction: this.executeFunction,
            attachmentData: this.attachmentData,
            profile: this.profileService.profileDetails(),
            clearAttachments: this.clearAttachments
        }
    }
})
export default class FormExecutionHandler extends BaseHandler {
    state: any = {};
    alert: Alert = {
        show: false,
        msg: ""
    };
    executions: any = {}
    stateConnectors: any = {}
    attachmentData: any = {
        attachments: [],
        attachmentsMetadata: []
    };

    profileContainer: Container = new Container();
    facilityListContainer: Container = new Container();
    commonContainer: Container = new Container();

    profileService!: IProfileService
    service!: IFormExecutionService;
    lookupService!: ILookupService;
    facilityListService!: IFacilityListService;
    commonStoreService!: ICommonStoreService;
    Helpers = Helpers;

    setInitialNavValue: (value: any) => void = inject("setInitialNavValue") as (
        value: any
    ) => void;
    flatteningJson = flatteningJson;
    ageCalculation = ageCalculation;
    calculateYearDifference = calculateYearDifference;
    getMonthDifference = getMonthDifference;
    isOnlySpaces = isOnlySpaces;
    addingDays = addingDays;
    getDynamicApiResponse(api: DynamicApi) {
        return this.service.getDynamicApiResponse(api);
    }

    executeFunction(executions: execution[], component: any = this) {
        try {
            return new CustomFunction(this, component).run(executions)
        }
        catch (ex: any) {
            console.error(ex);
        }
    }

    async handleDownload(id: string) {
        const fileResponse = await this.service.downloadFile(id);
        const file: File = new File([fileResponse.blob], String(fileResponse.resp.headers.get("x-filename")), { type: fileResponse.resp.headers.get("content-type") });
        const link = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = link;
        a.download = String(fileResponse.resp.headers.get("x-filename"));
        document.body.appendChild(a);
        a.click();
    }
    clearAttachments(response: any) {
        this.attachmentData.attachmentsMetadata = [];
        this.attachmentData.attachmentsMetadata.push(...response);
        this.attachmentData.attachments = []
    }
    beforeCreate() {
        bindFormExecutionService(this.IoC);
        bindProfileService(this.profileContainer);
        bindFacilityListService(this.facilityListContainer);
        bindCommonStoreService(this.commonContainer);
        this.service = this.IoC.get<IFormExecutionService>(IoCTypes.FormExecutionService);
        this.lookupService = this.IoC.get<ILookupService>(IoCTypes.LookupService);
        this.profileService = this.profileContainer.get<IProfileService>(IoCTypes.ProfileService);
        this.facilityListService = this.facilityListContainer.get<IFacilityListService>(IoCTypes.FacilityListService);
        this.commonStoreService = this.commonContainer.get<ICommonStoreService>(IoCTypes.CommonStoreService);
    }
}